<template>
  <div class="role-container">
    <ContentTitle>{{ tableTitle }}</ContentTitle>
    <Wfilter
      :filterConfig="filterConfig"
      @openRoleDialog="openRoleDialog"
      @print="handlePrint"
      @export="handleExport"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :pagination="pagination"
      :tableData="tableData"
      @update="openRoleDialog"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    ></Wtable>
    <Wdialog
      ref="roleDialog"
      :title="roleTitle"
      width="50%"
      @wConfirm="handleSure"
    >
      <el-form
        ref="roleFormRef"
        :model="roleFormData"
        :rules="roleFormRules"
        label-position="top"
        class="role-form"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="name" label="角色名称">
              <el-input
                v-model="roleFormData.name"
                autocomplete="off"
                placeholder="请输入角色名称"
                clearable
              /> </el-form-item
          ></el-col>
          <el-col :span="16">
            <el-form-item prop="desc" label="角色描述">
              <el-input
                v-model="roleFormData.desc"
                autocomplete="off"
                placeholder="请输入角色描述"
                clearable
              /> </el-form-item
          ></el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="选择成员角色" prop="group">
              <el-checkbox-group v-model="roleFormData.group">
                <el-checkbox
                  v-for="(item, index) in roleOptions"
                  :key="index"
                  :label="item.id"
                >
                  {{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>
<script>
import { ref, onMounted, computed, reactive } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  print,
  exportExcel,
  getTopBtnAuth,
  getBtnAuth,
} from "@/utils/common.js";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import API from "@/plugins/api.js";
import config from "./role.json";

export default {
  components: {
    Wtable,
    Wdialog,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const columns = reactive(config.tableConfig.list);
    const filterConfig = reactive(config.filterConfig);
    const pagination = config.tableConfig.pagination;
    const defaultBtns = reactive(config.tableConfig.btns);
    const tableData = ref([]);
    const page = ref(1);
    const tableTitle = ref("角色管理");
    const store = useStore();
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    // 获取权限按钮
    columns[columns.length - 1].btns = getBtnAuth(rightBtn.value, defaultBtns);
    filterConfig.btns = getTopBtnAuth(rightBtn.value, filterConfig.btns);
    function fetchData(parmas) {
      API.getRole({
        ...parmas,
        limit: pagination.pageSize,
        page: page.value,
      }).then((res) => {
        pagination.total = res.total;
        tableData.value = res.data;
        if (tableData.value.length) {
          tableData.value.forEach((el) => {
            el.source = el.source ? el.source : "";
          });
        }
      });
    }
    const roleOptions = ref([]);
    onMounted(() => {
      API.getMemberRole({}).then((res) => {
        roleOptions.value = res;
      });
      fetchData({});
    });
    const currentChange = (v) => {
      page.value = v;
      fetchData();
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    const roleDialog = ref(null);
    const roleFormRef = ref(null);
    const roleFormRules = {
      name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
      group: [
        {
          type: "array",
          required: true,
          message: "请选择成员角色",
          trigger: "change",
        },
      ],
    };
    const roleFormData = ref({
      name: "",
      desc: "",
      group: [],
    });

    const roleTitle = ref("");
    function openRoleDialog(data) {
      if (data && data.row) {
        //编辑
        roleTitle.value = "编辑角色";
        const rowData = JSON.parse(JSON.stringify(data.row));
        rowData.group = rowData.group_id;
        roleFormData.value = rowData;
      } else {
        // 新增
        roleTitle.value = "新增角色";
        roleFormData.value = {
          name: "",
          desc: "",
          group: [],
        };
      }

      roleDialog.value.show();
    }
    function handleSure() {
      if (roleFormRef.value) {
        roleFormRef.value.validate((valid) => {
          if (valid) {
            // 新增 或 编辑接口
            roleDialog.value.close();

            if (roleFormData.value.id) {
              //编辑
              API.editRole({
                id: roleFormData.value.id,
                name: roleFormData.value.name,
                desc: roleFormData.value.desc,
                group: roleFormData.value.group,
              }).then(() => {
                ElMessage.success({
                  message: "操作成功",
                });
                fetchData({});
              });
            } else {
              //新增
              API.addRole(roleFormData.value).then(() => {
                ElMessage.success({
                  message: "操作成功",
                });
                fetchData({});
              });
            }
          }
        });
      }
    }
    const propertiesConfig = ref([
      { field: "name", displayName: "角色" },
      { field: "desc", displayName: "描述" },
      { field: "node_str", displayName: "权限" },
      { field: "user_str", displayName: "成员" },
      { field: "source", displayName: "来源" },
    ]);
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      roleDialog,
      handleSure,
      roleFormData,
      roleFormRules,
      roleFormRef,
      openRoleDialog,
      roleOptions,
      filterConfig,
      roleTitle,
      sizeChange,
      handlePrint,
      handleExport,
      tableTitle,
    };
  },
};
</script>

<style lang='scss'>
.role-container {
  .header-action {
    .el-input__inner {
      background-color: var(--search-bg-color) !important;
    }
    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }
  .role-form {
    padding-bottom: 80px;
  }
}
</style>